import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './topbar.css';

function Topbar() {
  return (
    <div className="topbar-container">
        <div className='row'>
            <div className="offset-md-1 offset-lg-1 col-12 col-sm-12 col-md-11 col-lg-11 d-flex align-items-center px-4 px-sm-4 px-md-0 px-lg-0">
                <span><a href="https://www.facebook.com/MYFAMILYOFFICIAL"> <FontAwesomeIcon icon={['fab', 'facebook-f']} style={{color: "white"}} /></a></span>
                <span className="px-3"><a href="https://www.instagram.com/myfamily_forpets/"><FontAwesomeIcon icon={['fab', 'instagram']} style={{color: "white"}} /></a></span>
            </div>
        </div>

  </div>
  );
}

export default Topbar;
