import './App.css';
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import Footer from './components/footer/footer';
import Topbar from './components/topbar/topbar';
import Lottie from "lottie-react";
import notFoundAnimation from "./assets/dog_looking_for.json";
import logoLettering from "./assets/Lettering-positivo.svg"
import pictogram from './assets/loader.png'
import maintenance from './assets/20945781.jpg';

library.add(fab)

function App(props) {

  let error = props.error;

  console.log(error);

  return (
    <div className='app'>
      <Topbar/>
      <img src={logoLettering} alt='MyFamily Lettering' className='lettering'/>
      <div className="loader-container">
        <img src={maintenance} alt="Maintenance" className='maintenance' />
        <span class="attribution-text"><a href="http://www.freepik.com">Designed by vectorjuice / Freepik</a></span>
        <h2 class="error-text" dangerouslySetInnerHTML={errorMessage(props)}></h2>
        <img src={pictogram} alt='MyFamily Pictogram' className='pictogram'/>
				<Lottie className="loader" animationData={notFoundAnimation} loop={true} />
			</div>
      <Footer/>
    </div>
  );
}

function errorMessage(props) {
  return {__html: props.error.message}
}

export default App;
