import "./footer.css";

function Footer() {
	return (
		<div className="footer row">
			<div className="offset-md-1 offset-lg-1 col-12 col-sm-12 col-md-11 col-lg-11 px-2 d-flex justify-content-center align-items-center align-items-md-start flex-column">
				<div className="text-muted fs-15">Copyright @ 2023 MyFamily srl | P.IVA 02398620068</div>
				<div className="d-flex">
					<a id="privacyPolicyLink" href="https://www.iubenda.com/privacy-policy/60881857" className="text-muted iubenda-nostyle no-brand iubenda-noiframe iubenda-embed iubenda-noiframe fs-15" aria-current="page"> 
						Privacy Policy
					</a>
					<a id="cookiesPolicyLink" href="https://www.iubenda.com/privacy-policy/58850214/cookie-policy" className="text-muted iubenda-nostyle no-brand iubenda-noiframe iubenda-embed iubenda-noiframe px-2 fs-15">
						Cookie Policy
					</a>
					<a id="termsLink" href="https://www.iubenda.com/privacy-policy/58850214/cookie-policy" className="text-muted iubenda-nostyle no-brand iubenda-noiframe iubenda-embed iubenda-noiframe fs-15">
						<span data-ltag="terms">Terms and Conditions</span>
					</a>
				</div>
			</div>
		</div>
	);
}

export default Footer;
