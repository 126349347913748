import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
const catchedErrors = [
  {code: "500", message: "Internal Server Error"},
  {code: "501", message: "Not Implemented"},
  {code: "502", message: "Bad Gateway"},
  {code: "503", message: "Oops!<br />It looks like you caught us while we are working on our systems.<br />We love to work unnoticed,<br/>but this time we're probably doing some magic<br />that's worth the wait.<br />We're looking forward to seeing you when we're back."},
  {code: "504", message: "Gateway Timeout"},
  {code: "505", message: "HTTP Version Not Supported"},
  {code: "509", message: "Bandwidth Limit Exceeded"},
  {code: "400", message: "Bad Request"},
  {code: "401", message: "Unauthorized"},
  {code: "403", message: "Forbidden"},
  {code: "404", message: "Not Found"}
]
const error = catchedErrors.map(el => document.getElementsByName(el.code).length ? el : null).find(x => !!x) ?? {code: "Oops", message: "Something went wrong"};

root.render(
  <React.StrictMode>
    <App error={error}/>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
